import API from "./api";

export default class extends HTMLElement {
    id = "";
    parent = null;

    #promoteButton = null;
    #disableButton = null;

    async connectedCallback () {
        this.id = this.attributes.id.value;
        this.parent = this.closest("user-list");

		this.#promoteButton = this.querySelector("button.promote");

        if (this.#promoteButton) {
            this.#promoteButton.addEventListener("click", async (e) => {
                e.preventDefault();
                this.pressPromoteButton();
            });
        }

		this.#disableButton = this.querySelector("button.disable");

        if (this.#disableButton) {
            this.#disableButton.addEventListener("click", async (e) => {
                e.preventDefault();
                this.pressDisableButton();
            });
        }
    }

    async pressPromoteButton() {
		this.#promoteButton.setAttribute("disabled", "");
        await API.makeAdmin(this.id); 
        this.parent.render();
    }

    async pressDisableButton() {
		this.#disableButton.setAttribute("disabled", "");
        await API.deleteUser(this.id); 
        this.parent.render();
    }
}
