import API from "./api";

export default class extends HTMLElement {
    async connectedCallback () {
        return this.render()
    }

    async render () {
        try {
            const users = await API.getUsersList();

            var html = `<h3>Users list</h3>`;
            users.forEach( ({id, username, avatar, disabled, scopes}) => {
                var tags = "";
                var controls = "";

                if (scopes.includes("admin")) {
                    tags += `<span class="admin">admin</span>`;
                } else {
                    tags += `<span class="user">user</span>`;

                    if (!disabled) {
                        controls += `<button class="promote">Promote to admin</button>`;
                    }
                }

                if (disabled) {
                    tags += `<span class="disabled">disabled</span>`;
                } else if (!scopes.includes("admin"))  {
                    controls += `<button class="disable">Disable</button>`;
                }

                html += `
                    <user-detail id="${id}" class="${disabled ? " disabled" : ""}">
                        <div class="avatar">
                            <img src="${avatar}"/>
                            <span>${username}</span>
                        </div>
                        <div class="tags">
                            ${tags}
                        </div>
                        <div class="controls">
                            ${controls}
                        </div>
                    </user-detail>
                `
            })
            this.innerHTML = html;
            this.classList.add("loaded");
        } catch (e) {
            console.log(e);
            this.innerHTML = `<span class="error">You need to be an administrator to see this page</span>`;
        }
    }
}
