export default function (title, contents) {
    const dialog      = document.getElementById("proposal_dialog");
    const dialogTitle = document.getElementById("proposal_dialog_title");
    const dialogList  = document.getElementById("proposal_dialog_list");
    dialogTitle.innerHTML = title;
    dialogList.innerHTML = contents;
    dialog.showModal();
    return dialog;
}


