export const ShowSnackbar = (contents) => {
    const bar = document.getElementById("snackbar");

    bar.classList.add("show");
    bar.innerHTML = contents;

    // After 3 seconds, remove the show class from DIV
    setTimeout(() => { 
       bar.classList.remove("show");
    }, 3000);
}

export default ShowSnackbar;
