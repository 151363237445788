import API from "./api";
import { OpenInNewModalSVG } from "./icons";
import ShowSnackbar from "./snackbar";
import OpenDialog from "./dialog";

const Harms = () => window.map_data.Harms.map( harm => harm.Term );
const ResourceTypes = () => window.map_data["Resource Types"].map( rt => rt.Term );

export default class extends HTMLElement {
    #button = null;

    connectedCallback () {
        this.innerHTML = `
            <button>
                <span>Propose new resource</span>
                ${OpenInNewModalSVG}
            </button>
        `;
        this.#button = this.querySelector("button");

        this.#button.addEventListener("click", async (e) => {
            e.preventDefault();
            this.openDialog();
        }); 
    }

    openDialog() {
        const el = document.createElement("div");
        el.innerHTML = `
            <form id="propose-resource-form" method="dialog">
                <input type="text" required placeholder="Title" name="title" />
                <input type="url" required placeholder="URL" name="url" />
                <input type="text" required placeholder="Authors" name="authors" />
                <input type="text" required placeholder="Publication" name="publication" />
				<div>
					<label for="resource_type">Resource Type</label>
					${this.resourceTypesSelectControlMarkup()}
				</div>
				<div>
					<label for="harms">Harms</label>
					${this.harmsSelectControlMarkup()}
				</div>
                <textarea required placeholder="General reasoning. How is this resource relevant?" name="reasoning"></textarea>
                <button type="submit" value="confirm">Propose resource</button>
            </form>
        `;

        const dialog = OpenDialog("Propose a new resource", el.innerHTML);

		const handler = async (e) => { 
			dialog.removeEventListener("close", handler);
			if (e.target.returnValue !== "confirm") {
				return;
			}
			const form = e.target.querySelector("form#propose-resource-form");

			const formData = new FormData(form)

			const response = await API.proposeNewResource(
				formData.get("title"),
				formData.get("url"),
				formData.get("authors"),
				formData.get("publication"),
				formData.get("resource_type"),
				formData.getAll("harms"),
				formData.get("reasoning"),
			);

			if (response.status == 400) {
				const body = await response.text();
				OpenDialog("That didn't work", `<p style="grid-column: span 2">${this.translateAPIErrorMessages(body)}</p>`);
				return;
			}

            ShowSnackbar("Thank you for your proposal!");

            return false;
        }

        dialog.addEventListener("close", handler);
    }

	translateAPIErrorMessages(text) {
		if (text == "add resource: resource add url already exists") {
			return "A resource with that URL already exists. If you want to modify the existing resource record, then please make a change proposal."
		}
		return text;
	}

	harmsSelectControlMarkup() {
        const selectList = document.createElement("select");
        selectList.role = "new-harm-relation";
        selectList.name = "harms";
        selectList.id = "harms";
        selectList.multiple = true;

        Harms().forEach(harm => {
            const option = document.createElement("option");
            option.value = harm;
            option.text = harm;
            selectList.appendChild(option);
        })

        return selectList.outerHTML;
    }

	resourceTypesSelectControlMarkup() {
        const selectList = document.createElement("select");
        selectList.role = "new-rt-relation";
        selectList.name = "resource_type";
        selectList.id = "resource_type";

        ResourceTypes().forEach(rt => {
            const option = document.createElement("option");
            option.value = rt;
            option.text = rt;
            selectList.appendChild(option);
        })

        return selectList.outerHTML;
    }
}
