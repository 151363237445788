import API from "./api";
import ShowSnackbar from "./snackbar";
import OpenDialog from "./dialog";

export default class extends HTMLElement {
    #button = null;

    connectedCallback () {
        this.#button = this.querySelector("button");

        this.#button.addEventListener("click", async (e) => {
            e.preventDefault();
            this.openDialog();
        }); 
    }

    openDialog() {
        const el = document.createElement("div");
        el.innerHTML = `
            <form id="propose-resource-form" method="dialog">
                <input type="text" required placeholder="Term" name="term" />
                <textarea required placeholder="Description" name="description"></textarea>
                <textarea required placeholder="General reasoning. How is this positive novel?" name="reasoning"></textarea>
                <button type="submit" value="confirm">Propose new positive</button>
            </form>
        `;

        const dialog = OpenDialog("Propose a new positive", el.innerHTML);

        const handler = async (e) => { 
          dialog.removeEventListener("close", handler);
          if (e.target.returnValue !== "confirm") {
                return;
            }
			e.target.returnValue = "";
            const form = e.target.querySelector("form#propose-resource-form");

            const formData = new FormData(form)

            const response = await API.proposeNewPositive(
                formData.get("term"),
                formData.get("description"),
                formData.get("reasoning"),
            );

			if (response.ok) {
				ShowSnackbar("Thank you for your proposal!");
			} else {
				const body = await response.text();
				OpenDialog("That didn't work", `<p style="grid-column: span 2">${body}</p>`);
				return;
			}

			return false;
        }

        dialog.addEventListener("close", handler);
    }
}
