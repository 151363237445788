"use strict";
// import functions
import FocusAccessibility from "./modules/focus_accessibility";
import TypeRatioInterpolation from "./modules/type_ratio_interpolation";
import RephrainMap from "./modules/rephrain_map";
import API from "./modules/api";
import LoginState from "./modules/login_state";
import LoginAvatar from "./modules/login_avatar";
import UserList from "./modules/user_list";
import UserDetail from "./modules/user_detail";
import ProposeNewResource from "./modules/propose_new_resource";
import ProposeNewHarm from "./modules/propose_new_harm";
import ProposeNewPositive from "./modules/propose_new_positive";
import ProposeNewChallenge from "./modules/propose_new_challenge";
import ProposeResourceChange from "./modules/propose_resource_change";
import ProposeHarmChange from "./modules/propose_harm_change";
import ProposeHarmPositiveRelationship from "./modules/propose_harm_positive_relationship";
import ProposePositiveChange from "./modules/propose_positive_change";

/*
    Custom elemements for editing
*/
customElements.define("login-state", LoginState);
customElements.define("login-avatar", LoginAvatar);
customElements.define("user-list", UserList);
customElements.define("user-detail", UserDetail);
customElements.define("propose-new-resource", ProposeNewResource);
customElements.define("propose-new-harm", ProposeNewHarm);
customElements.define("propose-new-positive", ProposeNewPositive);
customElements.define("propose-new-challenge", ProposeNewChallenge);
customElements.define("propose-resource-change", ProposeResourceChange);
customElements.define("propose-harm-change", ProposeHarmChange);
customElements.define("propose-harm-positive-relationship", ProposeHarmPositiveRelationship);
customElements.define("propose-positive-change", ProposePositiveChange);

// Capture any OAuth logic
API.handleAuth();

// Store API on the global scope for development
window.API = window.API || API;

/*
    interpolate between type ratio minimum and maximum values,
    and store the result in a CSS custom property
*/
TypeRatioInterpolation();
/*
    handle display of map data
*/
RephrainMap();
/*
    allow enchanced focus detection (depends on a11y.js)
*/
FocusAccessibility();
