import API, { Events } from "./api";
import { subscribe } from "./pubsub";

const template = document.createElement("template");
template.innerHTML = `
  <slot id="dynamic" name="loading" />
`;

export default class extends HTMLElement {
     #slot

     connectedCallback () {
         this.attachShadow({mode: "open"});
         this.shadowRoot.appendChild(template.content.cloneNode(true));
         this.#slot = this.shadowRoot.querySelector("slot#dynamic");

         this.#slot.name = API.isLoggedIn ? "logged-in" : "logged-out";

         subscribe(Events.Login, () => this.#slot.name = "logged-in");
         subscribe(Events.Logout, () => this.#slot.name = "logged-out");

         this.classList.add("loaded");
     }
}
