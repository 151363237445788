import API, { Events } from "./api";
import { subscribe } from "./pubsub";

export default class extends HTMLElement {
    connectedCallback () {
        subscribe(Events.Login, () => this.update());
        subscribe(Events.Logout, () => this.update());
        this.update();
    }

    update () {
        const user = API.user;
        if (!user) {
            this.innerHTML = "Not logged in";
            return;
        }

        this.innerHTML = `<img src="${user.github_avatar}"/> <span>${user.github_username}</span>`;
    }
}
