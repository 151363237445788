import API from "./api";
import { OpenInNewModalSVG } from "./icons";
import ShowSnackbar from "./snackbar";
import OpenDialog from "./dialog";

export default class extends HTMLElement {
    #button = null;
    #originalTitle = ""
    #originalDescription = ""

    connectedCallback () {
        this.#originalTitle = this.attributes.original_title.value;
        this.#originalDescription = this.attributes.original_description.value;
        this.innerHTML = `
            <button>
                <span>Propose changes</span>
                ${OpenInNewModalSVG}
            </button>
        `;
        this.#button = this.querySelector("button");

        this.#button.addEventListener("click", async (e) => {
            e.preventDefault();
            this.openDialog();
        }); 
    }

    openDialog() {
        const el = document.createElement("div");
        el.innerHTML = `
            <form id="propose-resource-form" method="dialog">
                <input type="text" required placeholder="Title" name="title" value="${this.#originalTitle}" />
                <textarea required placeholder="Description" name="description">${this.#originalDescription}</textarea>
                <textarea required placeholder="General reasoning. Why is this change necessary?" name="reasoning"></textarea>
                <button type="submit" value="confirm">Propose change</button>
            </form>
        `;

        const dialog = OpenDialog("Propose a change to this harm", el.innerHTML);

        const handler = async (e) => { 
          dialog.removeEventListener("close", handler);
          if (e.target.returnValue !== "confirm") {
                return;
            }
            const form = e.target.querySelector("form#propose-resource-form");

            const formData = new FormData(form)

            await API.proposeHarmChange(
                this.#originalTitle,
                formData.get("title"),
                formData.get("url"),
                formData.get("reasoning"),
            );

            ShowSnackbar("Thank you for your proposal!");

            return false;
        }

        dialog.addEventListener("close", handler);
    }
}
