import API from "./api";
import { OpenInNewModalSVG } from "./icons";
import ShowSnackbar from "./snackbar";
import OpenDialog from "./dialog";

export default class extends HTMLElement {
    #button = null;

    connectedCallback () {
        this.innerHTML = `
            <button>
                <span>Propose new resource</span>
                ${OpenInNewModalSVG}
            </button>
        `;
        this.#button = this.querySelector("button");

        this.#button.addEventListener("click", async (e) => {
            e.preventDefault();
            this.openDialog();
        }); 
    }

    openDialog() {
        const el = document.createElement("div");
        el.innerHTML = `
            <form id="propose-resource-form" method="dialog">
                <input type="text" required placeholder="Title" name="title" />
                <input type="url" required placeholder="URL" name="url" />
                <textarea required placeholder="General reasoning. How is this resource relevant?" name="reasoning"></textarea>
                <button type="submit" value="confirm">Propose resource</button>
            </form>
        `;

        const dialog = OpenDialog("Propose a new resource", el.innerHTML);

        const handler = async (e) => { 
          dialog.removeEventListener("close", handler);
          if (e.target.returnValue !== "confirm") {
                return;
            }
            const form = e.target.querySelector("form#propose-resource-form");

            const formData = new FormData(form)

            await API.proposeNewResource(
                formData.get("title"),
                formData.get("url"),
                formData.get("reasoning"),
            );

            ShowSnackbar("Thank you for your proposal!");

            return false;
        }

        dialog.addEventListener("close", handler);
    }
}
