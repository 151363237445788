import API from "./api";
import { OpenInNewModalSVG } from "./icons";
import ShowSnackbar from "./snackbar";
import OpenDialog from "./dialog";

const Challenges = () => window.map_data["Challenges"].map( p => p.Title );

export default class extends HTMLElement {
    #button = null;
    #term = "";

    connectedCallback () {
        this.#term = this.attributes.term.value;
        this.innerHTML = `
            <button>
                <span>Add challenge</span>
                ${OpenInNewModalSVG}
            </button>
        `;
        this.#button = this.querySelector("button");

        this.#button.addEventListener("click", async (e) => {
            e.preventDefault();
            this.openDialog();
        }); 
    }

    openDialog() {
        const selectList = document.createElement("select");
        selectList.required = true;
        selectList.name = "challenge";

        const option = document.createElement("option");
        option.value = "";
        option.text = "New challenge relation";
        selectList.appendChild(option);

        Challenges().forEach(challenge => {
            const option = document.createElement("option");
            option.value = challenge;
            option.text = challenge;
            selectList.appendChild(option);
        })

        const el = document.createElement("div");
        el.innerHTML = `
            <form id="propose-resource-form" method="dialog">
                ${selectList.outerHTML}
                <textarea required placeholder="General reasoning. How is this relationship appropriate?" name="reasoning"></textarea>
                <button type="submit" value="confirm">Propose relationship</button>
            </form>
        `;

        const dialog = OpenDialog("Propose a new challenge relationship to this harm", el.innerHTML);

        const handler = async (e) => { 
          dialog.removeEventListener("close", handler);
          if (e.target.returnValue !== "confirm") {
                return;
            }
            const form = e.target.querySelector("form#propose-resource-form");

            const formData = new FormData(form)

            await API.proposeChallengeRelationshipToHarm(
                formData.get("challenge"),
                this.#term,
                formData.get("reasoning"),
            );

            ShowSnackbar("Thank you for your proposal!");

            return false;
        }

        dialog.addEventListener("close", handler);
    }
}
